<script>
  export default {
    props: {
      url: { type: String, default: "" },
    },
    data() {
      return {
      }
    },
  }
</script>
<template>
  <div>
    <b-modal id="ModalViewPDF" title="PDF" size="xl">  
      <template #default="{  }">
        <iframe :src="url" frameborder="0" style="height: 70vh; width: 100%"></iframe>
      </template>
    </b-modal>
  </div>
</template>