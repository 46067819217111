// import api from "@/helpers/api-rest/api.js";
import { parseISO, format } from 'date-fns';
export const methods = {
    addRowProfessorate(){
        this.essay?.essay_coordinator?.essay_professorates.push({
            id: '',
            name: '',
            department: '',
            dni: '',
            email: '',
            essay_coordinator_id: this.essay.essay_coordinator.id,
            essay_id: this.essay.id,
        });
    },
    addRowProfessoratePartner(center){
      // console.log(center);
      center.essay_professorates.push({
        id: '',
        name: '',
        department: '',
        dni: '',
        email: '',
      });
    },
    addRowPartner(){
      this.essay?.partner_companies.push({
          id: '',
          name: '',
          cif: '',
          address: '',
          responsable: '',
          email: '',
          document_path: '',
          document_name: 'Selecciona un archivo...',
          essay_id: this.essay.id,
      });
    },
    addRowAction(){
      const currentDate = new Date().toISOString().slice(0, 10);
      this.essay?.actions?.push({
          id: '',
          name: '',
          descriptions: '',
          init_date: currentDate,
          end_date: currentDate,
          responsables: '',
          risks: '',
          contingency_plan: '',
          essay_id: this.essay.id,
          education_activities: [],
          economic_forecats: []
      });
    },
    addRowCourse(){
      this.essay?.courses.push({
        id: '',
      });
    },
    addRowResults(target){
      console.log(target);
      target.target_results.push({
          id: '',
          target_id: target.id,
          title: '',
          description: '',
      });
    },
    addRowResultAchievement(result){
      result.result_achievements.push({
          id: '',
          name: '',
          description: '',
          measured_values: '',
          target_result_id: result.id,
      });
    },
    addRowAchievement(target){
      target.target_achievements.push({
          id: '',
          name: '',
          description: '',
          measured_values: '',
          target_id: target.id,
      });
    },
    addRowActivity(action){
      action.education_activities.push({
          id: null,
          name: '',
          methodology: '',
      });
    },
    addRowForecast(action){
      action.economic_forecats.push({
          id: null,
          material: '',
          cost: '',
      });
    },
    addRowCenterPartner(index){
      this.essay?.essay_center_partner?.push({
          id: '',
          essay_id: this.essay.id,
          center_id: index,
          document_path: '',
          document_name: 'Selecciona un archivo...',
          essay_professorates: []
      });
    },
    addRow(){
      this.essay.targets.push({
          id: '',
          essay_id: this.essay.id,
          title: '',
          description: '',
          target_results: []
      });
    },
    deleteRowProfessorate(key){
      this.essay?.essay_coordinator?.essay_professorates.splice(key, 1);
    },
    deleteRowCourse(key){
      this.essay?.courses.splice(key, 1);
    },
    deleteRowPartner(key){
      this.essay?.partner_companies.splice(key, 1);
    },
    deleteCenterPartner(key){
      this.essay?.essay_center_partner.splice(key, 1);
    },
    deleteRowAction(key){
      console.log(key);
      this.essay?.actions.splice(key, 1);
    },
    deleteRowActionActivity(index, key){
      this.essay.actions[index].education_activities.splice(key, 1);
      console.log(this.essay.actions[index]);
    },
    deleteRowActionForecast(index, key){
      this.essay.actions[index].economic_forecats.splice(key, 1);
      // console.log(this.essay.actions[index]);
    },
    deleteRowTargetAchievement(index, key){
      this.essay.targets[index].target_achievements.splice(key, 1);
    },
    deleteRowResultAchievement(index, result, key){
      this.essay.targets[index].target_results[result].result_achievements.splice(key, 1);
    },
    formatDate(date) {
      return format(parseISO(date), 'yyyy-MM-dd');
    },
    updateDate(event, index, field) {
      const value = event.target.value;
      this.essay.actions[index][field] = value;
    }
}