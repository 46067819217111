<script>
import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';
import store from '@/state/store';
// import EssayActionModal from '@/components/modals/essay-actions-modal.vue';


export default {
  // components: { EssayActionModal },
  props: {
    id: { type: String, default: "ModalViewEssay" },
    essay: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataEssay: {
        targets: [],
        essay_coordinator: {
          center: [],
          essay_professorates: [],
        },
        essay_center_partner: [],
        partner_companies: [],
        courses: [],
        actions: [],
      },
      dataUser: [],
      loading: false,
    };
  },
  computed: {
    modalTitle() {
      // return "Redacción #" + this.essay?.id ;
      return "" ;
    },
  },
  methods: {
    showElement(roleId = [], show = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show) {
        return true;
      }
      return false;
    },
    loadData(){
      this.loading = true;
      const apiEndpoint = `/api/v1/essays/${this.essay?.id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          const data = response?.data?.data;
          data.targets.map(target => {
            target.showDetail = false;
            target.target_results.map(result => {
              result.showDetail = false;
            });
            return target;
          });
          data.essay_center_partner.map(center_partner => {
            center_partner.showDetail = false;
            return center_partner;
          });
          data.actions.map(action => {
            action.showDetail = false;
            action.init_date = format(new Date(action.init_date), 'dd/MM/yyyy');
            action.end_date = format(new Date(action.end_date), 'dd/MM/yyyy');
            return action;
          });

          data.formattedCreatedAt = format(new Date(data.created_at), 'dd/MM/yyyy');

          this.dataEssay = data;
          console.log(this.dataEssay);
          this.loadDataUser(this.dataEssay.created_by_id);
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.error("Error loading data:", error);
        });
    },
    loadDataUser(id){
      const apiEndpoint = `/api/v1/users/${id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.dataUser = response?.data?.data;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
    downloadFile(filePath){
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/download?file=${filePath}`;
      window.open(url, '_self');
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" :hide-footer="!showElement([1])" :title="modalTitle" @shown="loadData" scrollable size="extra-xl">
    <template #default="{ }">
      <div class="text-center text-primary qlk-spinner" v-if="loading">
        <span aria-hidden="true" class="align-middle spinner-border"></span>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row mb-3">
            <div class="col-lg-8">
              <b-card class="mb-3 h-100">
                <b-card-title>Información General del Ensayo</b-card-title>
                <b-card-text><strong>Título:</strong> {{ dataEssay?.name }}</b-card-text>
                <b-card-text><strong>Descripción:</strong> {{ dataEssay?.description }}</b-card-text>
                <b-card-text><strong>Estado del arte:</strong> {{ dataEssay?.arte_status }}</b-card-text>
                <b-card-text><strong>Número de centros:</strong> {{ dataEssay?.centers_number }}</b-card-text>
                <b-card-text><strong>Número de empresas:</strong> {{ dataEssay?.companies_number }}</b-card-text>
                <b-card-text><strong>Número de profesorado:</strong> {{ dataEssay?.professorates_number }}</b-card-text>
                <b-card-text><strong>Número de estudiantes:</strong> {{ dataEssay?.students_number }}</b-card-text>
                <!-- <b-card-text><strong>Estado:</strong> {{ dataEssay.essay_status.name }}</b-card-text> -->
              </b-card>
            </div>
            <div class="col-lg-4">
              <b-card class="mb-3 h-100">
                <b-card-title>Usuario</b-card-title>
                <b-card-text><strong>Nombre:</strong> {{ dataUser?.name }}</b-card-text>
                <b-card-text><strong>DNI:</strong> {{ dataUser?.dni }}</b-card-text>
                <b-card-text><strong>Email:</strong> {{ dataUser?.email }}</b-card-text>
                <b-card-text><strong>Centro:</strong> {{ dataUser?.center?.name }}</b-card-text>
                <b-card-text><strong>Fecha de creación:</strong> {{ dataEssay?.formattedCreatedAt }}</b-card-text>
                <!-- <b-card-text><strong>Estado:</strong> {{ dataEssay.essay_status.name }}</b-card-text> -->
              </b-card>
            </div>
          </div>
          <b-card class="mb-3">
            <b-card-title class="underline-title">Líneas estratégicas</b-card-title>
            <div v-for="line in dataEssay?.strategic_lines" :key="line.id">
              <div v-if="dataEssay?.strategic_lines && dataEssay?.strategic_lines.length > 0">
                <ul>
                  <li>
                    {{ line.description }}
                  </li>
                </ul> 
              </div>
              <div v-else>
                No hay resultados
              </div>
            </div>
          </b-card>
          <b-card class="mb-3">
            <b-card-title class="card-title underline-title">Objetivos</b-card-title>
            <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th style="width: 100px;">#</th>
                  <th>Descripción</th>
                  <th style="width: 10px;"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="target in dataEssay.targets">
                  <tr :key="target.id">
                    <td>{{ target.id }}</td>
                    <td>{{ target.description }}</td>
                    <td>
                      <b-icon style="margin-right: 10px; cursor: pointer;" :icon="target.showDetail ? 'chevron-down' : 'chevron-up'" @click="toggle(target)"></b-icon>
                    </td>
                  </tr>
                  <tr v-show="target.showDetail" v-for="result in target.target_results" :key="result.id">
                    <td colspan="3">
                      <b-card-sub-title class="card-sub-title">Indicador de realización de objetivos</b-card-sub-title>
                      <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light">
                          <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Valores de medida</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="t_achievement in target.target_achievements">
                            <tr :key="t_achievement.id">
                              <td>{{ t_achievement.name }}</td>
                              <td>{{ t_achievement.description }}</td>
                              <td>{{ t_achievement.measured_values }}</td>
                            </tr>
                          </template>
                          <tr v-if="!target.target_achievements || target.target_achievements.length == 0">
                            <td colspan="4" class="text-center">
                              No hay datos
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <b-card-sub-title class="card-sub-title mt-4 mb-4">Resultados</b-card-sub-title>
                      <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light">
                          <tr>
                            <th>Descripción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr :key="result.id">
                            <td>{{ result.description }}</td>
                            <td>
                              <b-icon style="margin-right: 10px; cursor: pointer;" :icon="result.showDetail ? 'chevron-down' : 'chevron-up'" @click="toggle(result)"></b-icon>
                            </td>
                          </tr>
                          <tr v-show="result.showDetail" :key="'result-details-' + result.id">
                            <td colspan="3">
                              <b-card-sub-title class="card-sub-title">Indicador de realización de resultados</b-card-sub-title>
                              <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                <thead class="thead-light">
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Valores de medida</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template v-for="r_achievement in result.result_achievements">
                                    <tr :key="r_achievement.id">
                                      <td>{{ r_achievement.name }}</td>
                                      <td>{{ r_achievement.description }}</td>
                                      <td>{{ r_achievement.measured_values }}</td>
                                    </tr>
                                  </template>
                                  <tr v-if="!result.result_achievements || result.result_achievements.length == 0">
                                    <td colspan="4" class="text-center">
                                      No hay datos
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <!-- <template v-for="professorate in center_partner.essay_professorates">
                            <tr :key="professorate.id">
                              <td>{{ professorate.name }}</td>
                              <td>{{ professorate.department }}</td>
                              <td>{{ professorate.dni }}</td>
                              <td>{{ professorate.email }}</td>
                            </tr>
                          </template> -->
                        </tbody>
                      </table>
                      <!-- <div v-if="target.target_results && target.target_results.length > 0">
                        <ul>
                          <li>
                            {{ result.description }}
                          </li>
                        </ul> 
                      </div>
                      <div v-else>
                        No hay resultados
                      </div> -->
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </b-card>
          <b-card class="mb-3">
            <b-card-title class="underline-title">Centro coordinador</b-card-title>
            <b-card-sub-title class="card-sub-title mb-2"><b>Centro:</b> {{ dataEssay?.essay_coordinator?.center?.name}}</b-card-sub-title>
            <div class="form-inline">
              <b-card-sub-title class="card-sub-title"><b>Documento de aprobación del claustro y consejo escolar:</b> </b-card-sub-title>
              <div v-if="dataEssay?.essay_coordinator?.document_path">
                <b-button variant="info" @click.prevent="downloadFile(dataEssay?.essay_coordinator?.document_path)">
                  <i class="fas fa-file-download mr-2"></i> Descargar
                </b-button>
                <!-- <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFile(dataEssay.essay_coordinator.document_path)">
                  Descargar documento
                </a> -->
              </div>
            </div>
            <b-card-sub-title class="card-sub-title">Profesorado</b-card-sub-title>
            <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th>Nombre</th>
                  <th>Departamento</th>
                  <th>DNI</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="professorate in dataEssay.essay_coordinator.essay_professorates">
                  <tr :key="professorate.id">
                    <td>{{ professorate.name }}</td>
                    <td>{{ professorate.department }}</td>
                    <td>{{ professorate.dni }}</td>
                    <td>{{ professorate.email }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <b-card-sub-title class="card-sub-title">Ciclos formativos relacionados</b-card-sub-title>
            <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th>Código</th>
                  <th>Ciclo formativo</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="course in dataEssay.courses">
                  <tr :key="course.id">
                    <td>{{ course.mode_code }}</td>
                    <td>{{ course.education }} {{ course.mode }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </b-card>
          <b-card class="mb-3">
            <b-card-title class="underline-title">Centros colaboradores</b-card-title>
            <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th style="width: 100px;">#</th>
                  <th>Centro</th>
                  <th>Documento</th>
                  <th style="width: 10px;"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="center_partner in dataEssay.essay_center_partner">
                  <tr :key="center_partner.id">
                    <td>{{ center_partner.id }}</td>
                    <td>{{ center_partner.center.name }}</td>
                    <td>
                      <div v-if="center_partner.document_path">
                        <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFile(center_partner.document_path)">
                          <i class="mdi mdi-file-download-outline font-size-18"></i>
                        </a>
                      </div>
                      <div v-else>No hay documento</div>
                    </td>
                    <td>
                      <b-icon style="margin-right: 10px; cursor: pointer;" :icon="center_partner.showDetail ? 'chevron-down' : 'chevron-up'" @click="toggle(center_partner)"></b-icon>
                    </td>
                  </tr>
                  <tr v-show="center_partner.showDetail" :key="'details-' + center_partner.id">
                    <td colspan="3">
                      <b-card-sub-title class="card-sub-title">Profesorado</b-card-sub-title>
                      <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light">
                          <tr>
                            <th>Nombre</th>
                            <th>Departamento</th>
                            <th>DNI</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="professorate in center_partner.essay_professorates">
                            <tr :key="professorate.id">
                              <td>{{ professorate.name }}</td>
                              <td>{{ professorate.department }}</td>
                              <td>{{ professorate.dni }}</td>
                              <td>{{ professorate.email }}</td>
                            </tr>
                          </template>
                          <tr v-if="!center_partner.essay_professorates || center_partner.essay_professorates.length == 0">
                            <td colspan="4" class="text-center">
                              No hay datos
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <b-card-sub-title class="card-sub-title">Empresas colaboradoras</b-card-sub-title>
            <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th>Nombre</th>
                  <th>CIF</th>
                  <th>Dirección</th>
                  <th>Responsable</th>
                  <th>Email</th>
                  <th>Documento</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="company in dataEssay.partner_companies">
                  <tr :key="company.id">
                    <td>{{ company.name }}</td>
                    <td>{{ company.cif }}</td>
                    <td>{{ company.address }}</td>
                    <td>{{ company.responsable }}</td>
                    <td>{{ company.email }}</td>
                    <td>
                      <div v-if="company.document_path">
                        <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFile(company.document_path)">
                          <i class="mdi mdi-file-download-outline font-size-18"></i>
                        </a>
                      </div>
                      <div v-else>No hay documento</div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </b-card>
          <b-card class="mb-3">
            <b-card-title class="underline-title">Planificación de acciones</b-card-title>
            <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
              <thead class="thead-light">
                <tr>
                  <th>Acciones:</th>
                  <th style="width: 10px;"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="action in dataEssay.actions">
                  <tr :key="action.id">
                    <td>{{ action.name }}</td>
                    <td>
                      <b-icon style="margin-right: 10px; cursor: pointer;" :icon="action.showDetail ? 'chevron-down' : 'chevron-up'" @click="toggle(action)"></b-icon>
                    </td>
                  </tr>
                  <tr v-show="action.showDetail" :key="'details-' + action.id">
                    <td colspan="3">
                      <b-card-text><strong>Nombre:</strong> {{ action.name }}</b-card-text>
                      <b-card-text><strong>Descripción:</strong> {{ action.descriptions }}</b-card-text>
                      <b-card-text><strong>Fecha inicial:</strong> {{ action.init_date }}</b-card-text>
                      <b-card-text><strong>Fecha final:</strong> {{ action.end_date }}</b-card-text>
                      <b-card-text><strong>Responsable:</strong> {{ action.responsables }}</b-card-text>
                      <b-card-text><strong>Riesgos:</strong> {{ action.risks }}</b-card-text>
                      <b-card-text><strong>Plan de contingencia:</strong> {{ action.contingency_plan }}</b-card-text>
                    </td>
                  </tr>
                  <tr v-show="action.showDetail" :key="'activities-' + action.id">
                    <td colspan="3">
                      <b-card-sub-title class="card-sub-title">Actividades educativas</b-card-sub-title>
                      <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light">
                          <tr>
                            <th>Nombre</th>
                            <th>Metodología</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="activity in action.education_activities">
                            <tr :key="activity.id">
                              <td>{{ activity.name }}</td>
                              <td>{{ activity.methodology }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr v-show="action.showDetail" :key="'forecasts-' + action.id">
                    <td colspan="3">
                      <b-card-sub-title class="card-sub-title">Previsión coste económico</b-card-sub-title>
                      <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead class="thead-light">
                          <tr>
                            <th>Material</th>
                            <th>Coste (€)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="forecast in action.economic_forecats">
                            <tr :key="forecast.id">
                              <td>{{ forecast.material }}</td>
                              <td>{{ forecast.cost }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </b-card>
        </div>
      </div>
    </template>
    <template #modal-footer="{  }">
      <div class="d-flex justify-content-center w-100">
        <b-button @click="$emit('open-action-modal', 'reject', dataEssay?.id)" variant="danger" class="mx-5 w-25">Rechazar</b-button>
        <b-button @click="$emit('open-action-modal', 'return', dataEssay?.id)" variant="primary" class="mx-5 w-25">Devolución</b-button>
        <b-button @click="$emit('open-action-modal', 'approve', dataEssay?.id)" variant="success" class="mx-5 w-25">Aprobar</b-button>
      </div>
      <!-- <EssayActionModal :id="essayActionModalId" :actionType="actionType"/> -->
    </template>
  </b-modal>
</template>
